.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Saira+Extra+Condensed&display=swap");

:root {
  --primary-white: #fff;
  --primary-orange: #f9ab00;
  --primary-dark: rgb(52, 58, 64);
  --primary-red: #ef4035;
  --primary-hover-red: #f33501;
  --primary-purple: #4b0082;
  --primary-light-grey: #f4f4f4;
  --primary-grey: #ccd1d9;
  --primary-dark-grey: #666666;
  --primary-black: #000000;
  --primary-transparent-black: rgba(0, 0, 0, 0.7);
  --primary-green: green;
  --primary-transparent: rgba(0, 0, 0, 0);
}

body {
  font-family: "Roboto Condensed", sans-serif !important;
}

/************************** NAVBAR ************************************/

nav {
  z-index: 2;
}

.logo {
  width: 5rem;
}

nav .line {
  position: relative;
  top: -0.7rem;
  width: 0;
  height: 0.15rem;
  background: var(--primary-purple);
  display: inline-block;
}

/*----------- MENU LINKS -----------*/
nav a.nav-link {
  letter-spacing: 0.0375px;
  cursor: pointer;
}

.navbar.bg-dark {
  background: transparent !important;
}

.navbar.bg-dark.scrolled {
  background-color: var(--primary-black) !important;
  transition: 0.4s ease-in-out;
}

nav ul li {
  text-transform: uppercase;
}

.navbar-collapse {
    justify-content: center;
}

.navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: var(--primary-white) !important;
  border-top: 0.1875rem solid var(--primary-transparent);
}

.navbar-brand {
  max-width: 17.25rem;
  padding: 0 !important;
  height: 6rem !important;
}

.navbar-nav > li > a.active,
.navbar-nav > li > a.active:focus {
  border-top: 0.1875rem solid var(--primary-purple);
  transition: 0.5s ease-in-out;
}

.navbar-nav > li > a:hover {
  border-top: 0.1875rem solid var(--primary-purple);
}

@media (max-width: 991px) {
  .navbar-brand {
    width: 7rem;
  }

  .navbar.bg-dark {
    background: var(--primary-dark) !important;
  }
  .navbar.bg-dark.scrolled {
    background-color: var(--primary-dark) !important;
    transition: none;
  }
}

/************************** HEADER ************************************/

.header-wrapper {
  position: relative;
  background: url(background.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.main-info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 2;
}

.main-info h1 {
  color: var(--primary-white);
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .main-info h1 {
    font-size: 1.7rem !important;
  }
  .typed-text {
    font-size: 1.3rem !important;
  }
}

/************* TYPED TEXT ***************/
.typed-text {
  font-size: 2rem;
  color: var(--primary-white);
}

/************* HEADER MAIN OFFER BUTTON ***************/
.home-buttons {
  margin: 2rem;
}

.btn-main-offer {
  border: 1px solid var(--primary-purple);
  background-color: var(--primary-purple);
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  color: var(--primary-white);
  margin: 2rem;
}

.btn-main-offer:hover {
  text-decoration: none;
  border: 1px solid var(--primary-white);
  color: var(--primary-white);
  transition: 0.2s ease-in-out;
}

/************* PARTICLES JS ***************/
#canvas {
  position: absolute !important;
  height: 100vh !important;
  width: 100vw !important;
  z-index: 1 !important;
}

@media (max-width: 768px) {
  .particles-canvas {
    display: none;
  }
}

/************************** ABOUT ME ************************************/
.about {
  margin-top: 5rem;
}

.photo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-img {
  width: 21rem;
  border-radius: 50%;
  padding: 0.3rem;
  border: 2px solid var(--primary-purple);
}

.about-heading {
  color: var(--primary-purple);
  text-transform: uppercase;
}

@media (max-width: 468px) {
  .profile-img {
    width: 10rem;
  }
}

/************************** SERVICES ************************************/
.services {
  text-align: center;
  padding-bottom: 2rem;
  background: var(--primary-light-grey);
}

.services h1 {
  color: var(--primary-purple);
  text-transform: uppercase;
}

.services .circle {
  position: relative;
  margin: 0.375rem auto;
  background: var(--primary-purple);
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
}

.services .icon {
  font-size: 1.4rem;
  color: var(--primary-white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.services .box {
  height: 16rem;
  border-bottom: 0.3125rem solid var(--primary-purple);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: var(--primary-white);
  transition: 0.3s ease-in-out;
}

.services .box:hover {
  background: var(--primary-purple);
  border-bottom: 0.3125rem solid var(--primary-dark);
}

.services .box:hover .icon {
  color: var(--primary-purple);
}

.services .box:hover .circle {
  background: var(--primary-white);
}

.services .box:hover p {
  color: var(--primary-white);
}

.services .box:hover h3 {
  color: var(--primary-white);
}

/************************** EXPERIENCE ************************************/

.experience {
  padding: 0.7rem;
  margin-top: 3rem;
}

.experience-wrapper {
  position: relative;
  padding: 3.125rem 0;
  overflow: hidden;
}

.experience h1 {
  text-transform: uppercase;
  color: var(--primary-purple);
}

.experience-wrapper:before {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  margin-left: -0.0625rem;
  width: 0.125rem;
  height: 100%;
  background: var(--primary-grey);
  z-index: 1;
}

.timeline-block {
  width: calc(50% + 0.5rem);
  display: flex;
  justify-content: space-between;
  clear: both;
}

.timeline-block-right {
  float: right;
}

.timeline-block-left {
  float: left;
  direction: rtl;
}

.marker {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.3125rem solid var(--primary-dark);
  background: var(--primary-purple);
  margin-top: 0.625rem;
  z-index: 3;
}

.timeline-content {
  width: 95%;
  padding: 0 0.9375rem;
  color: var(--primary-dark-grey);
  direction: ltr;
}

.timeline-content h3 {
  margin-top: 0.3125rem;
  color: var(--primary-purple);
  font-weight: 900;
}

.timeline-pulsating-circle {
  content: "";
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 50px;
  background-color: var(--primary-purple);
  animation: pulsate 3s infinite;
}

.timeline-pulsating-wrapper {
  display: flex;
  justify-content: space-between;
  width: calc(100% + 3.5rem);
  margin-bottom: 1rem;
}

@media (max-width: 1200px) {
  .timeline-pulsating-wrapper {
    display: flex;
    justify-content: space-between;
    width: calc(100% + 3.25rem);
    margin-bottom: 1rem;
  }
}

@media (max-width: 992px) {
  .timeline-pulsating-wrapper {
    display: flex;
    justify-content: space-between;
    width: calc(100% + 2.85rem);
    margin-bottom: 1rem;
  }
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}


/*----------------- MEDIA ----------------------*/
@media (max-width: 768px) {
  .experience .container {
    padding-left: 0;
  }

  .experience-wrapper:before {
    left: 0.5rem;
    margin-bottom: 1.875rem;
  }
  .timeline-block-right {
    float: none;
    width: 100%;
  }

  .timeline-block-left {
    float: none;
    direction: ltr;
    width: 100%;
  }

  .timeline-pulsating-wrapper{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;
  }

  .timeline-pulsating-list-item{
    width: 100%
  }


  .timeline-pulsating-circle {
    content: "";
    position: absolute;
    right: 96.8%;
    display: block;
    width: 17px;
    height: 17px;
    box-sizing: border-box;
    border-radius: 50px;
    background-color: var(--primary-purple);
    animation: pulsate 3s infinite; 
  }
}

/************************** PORTFOLIO ************************************/

.portfolio-wrapper {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background-color: var(--primary-dark);
  justify-content: center;
  text-align: left;
}

.subheading {
  font-family: "Saira Extra Condensed", sans-serif !important;
  font-size: 4rem;
  font-weight: 400;
  line-height: 3rem;
  padding-left: 1.5rem;
  padding-top: 1.5rem;
  color: var(--primary-purple);
  text-align: center;
  margin-bottom: 2rem;
}

.subheading-project {
  font-family: "Saira Extra Condensed", sans-serif !important;
  font-size: 4rem;
  font-weight: 400;
  line-height: 3rem;
  padding-left: 1.5rem;
  padding-top: 1.5rem;
  color: white;
  text-align: center;
  margin-bottom: 2rem;
}

.subheading2 {
  font-family: "Saira Extra Condensed", sans-serif !important;
  font-size: 2rem;
  font-weight: 400;
  line-height: 3rem;
  padding-left: 1.5rem;
  color: var(--primary-purple);
  text-align: center;
  margin-bottom: 2rem;
}

.subheading2-project {
  font-family: "Saira Extra Condensed", sans-serif !important;
  font-size: 2rem;
  font-weight: 400;
  line-height: 3rem;
  padding-left: 1.5rem;
  color: white;
  text-align: center;
  margin-bottom: 2rem;
}

.work-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.right {
  float: right !important;
}

.parent {
  width: 48%;
  margin-bottom: 4%;
  height: 30rem;
  overflow: hidden;
  position: relative;
  float: left;
  display: inline-block;
  cursor: pointer;
}

.child {
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5 ease;
}

.project-link {
  opacity: 0;
  font-size: 2rem;
  color: #ffffff !important;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  text-transform: uppercase;
  text-align: center;
  padding: 1rem;
}

.parent:hover .child,
.parent:focus .child {
  -ms-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
}

.parent:hover a,
.parent:focus a {
  opacity: 1;
  text-decoration: none;
}

/*----------------- DETAILS ----------------------*/

.portfolio-details {
  display: none;
  margin: auto;
  font-weight: 500;
  font-size: 1.2rem;
  width: 100%;
}

.portfolio-details a {
  padding: 1rem;
}

.img-header {
  margin-top: 2rem;
  height: 30rem;
  width: 100%;
  background-size: cover;
}

.portfolio-header {
  display: flex;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}

.portfolio-company-logo {
  width: 100%;
  height: 100%;
  max-width: 5rem;
}

.spotify-logo {
  font-size: 2.6rem !important;
}

.project-title {
  font-family: "Saira Extra Condensed", sans-serif !important;
  font-size: 2rem;
  font-weight: bold;
  line-height: 3rem;
  padding-left: 1.5rem;
}

@media (max-width: 992px) {
  .parent {
    width: 100%;
    height: 20rem;
  }
  .img-header {
    height: 3rem;
    background-image: none !important;
  }
}

@media (min-width: 750px) {
  .project-title {
    font-size: 3rem;
  }
}

/************************** TESTIMONIALS ************************************/
.testimonials {
  background-image: url(testimonials-bg.jpg);
  background-size: cover;
  background-color: var(--primary-black);
  text-align: center;
  padding-bottom: 4rem;
  margin-bottom: 0;
  width: 100%;
}

.testimonials h1 {
  color: var(--primary-white);
  padding: 3rem 0;
  text-transform: uppercase;
}

.testimonials-content {
  text-align: center;
  max-width: 53.125rem;
  margin: 0 auto 2rem auto;
  background: var(--primary-transparent-black);
}

.myCarousel {
  color: var(--primary-white);
  background: var(--primary-transparent-black);
  margin-top: -6%;
  width: 54%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  height: 17.875rem;
}

.myCarousel h3 {
  color: var(--primary-purple);
  font-weight: 600;
  text-transform: uppercase;
}

.myCarousel p {
  color: var(--primary-grey);
}

.carousel .slide img {
  width: 10rem !important;
  border-radius: 50%;
  padding-top: 2rem;
}

.carousel .slide {
  background: transparent !important;
  height: 100% !important;
}

.carousel-root {
  margin: auto !important;
  margin-top: 3% !important;
}

/************************** CONTACTS ************************************/
.contacts {
  background: var(--primary-dark);
  padding: 3rem 0;
}

.contacts h1 {
  color: white;
  padding: 1.3rem 0;
  text-transform: uppercase;
}

.contacts p {
  color: var(--primary-light-grey);
  font-size: 1.2rem;
}

/*----------------- INPUTS----------------------*/

.contacts input,
.contacts textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 0.125rem solid var(--primary-dark-grey);
  border-radius: 0;
  color: var(--primary-white);
  outline: 0 !important;
  box-shadow: none !important;
  margin-bottom: 1rem;
  padding-left: 0 !important;
}

.contacts .container {
  padding: 4rem 0;
}

.contacts input:focus,
.contacts textarea:focus {
  background: transparent;
  color: var(--primary-white);
  border-bottom: 0.125rem solid var(--primary-dark-grey);
}

.contacts textarea {
  height: 12.4rem !important;
}

.contact-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  background: none;
  border: 1px solid white;
}

.contact-btn:hover {
  border: 1px solid var(--primary-purple);
  background-color: var(--primary-purple);
}

.line {
  position: relative;
  top: -0.7rem;
  width: 0;
  height: 0.15rem;
  background: var(--primary-purple);
  display: inline-block;
}

input:focus + .line,
textarea:focus + .line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

input.form-control {
  margin: -0.3rem 0;
}

textarea.form-control {
  margin: -0.3rem 0;
}

.error-message {
  color: var(--primary-white);
  background: var(--primary-hover-red);
  text-align: left;
}

.success-message {
  font-weight: 900;
  color: var(--primary-green);
}

/*----------------- MEDIA ----------------------*/
@media (max-width: 768px) {
  .contacts {
    overflow-x: hidden;
  }

  .contacts .container {
    padding: 0 1rem;
  }

  .contacts p {
    padding: 0 0.7rem;
  }
}

/************************** FOOTER ************************************/
.footer {
  background: var(--primary-black);
  color: var(--primary-dark-grey);
  padding: 3rem;
}

.footer a {
  text-decoration: none;
  color: var(--primary-dark-grey);
}

.footer a:hover {
  text-decoration: none;
  color: var(--primary-purple);
  cursor: pointer;
}

.footer p {
  margin-bottom: 0;
}

.footer-title {
  text-align: center;
  padding: -0.3rem;
  color: var(--primary-purple);
}

.footer-nav {
  margin: 0;
}

.footer .box {
  align-content: center;
  text-align: center;
}

.footer .nav-box {
  text-align: center;
}

.footer .icon {
  margin-right: 1rem;
  margin-left: 1rem;
}
